export var language = {
    en: 'German',
    de: 'English'
};
export var subTitle = {
    en: 'Curriculum Vitæ',
    de: 'Lebenslauf'
};
export var since = {
    en: 'since',
    de: 'seit'
};
export var email = {
    en: 'email',
    de: 'E-Mail Adresse'
};
export var linkedIn = {
    en: 'LinkedIn profile',
    de: 'LinkedIn Profil'
};
export var tagsFilterLabel = {
    en: 'filter by tags',
    de: 'nach Schlagworten filtern'
};
export var skillLevelFilterLabel = {
    en: 'only show skills with following self-assessment (or higher)',
    de: 'nur Skills mit folgender Selbsteinschätzung (und höher) anzeigen'
};
export var nonTechFilterLabel = {
    en: 'hide non-tech',
    de: 'non-tech verstecken'
};
export var contactMe = {
    en: 'Do you think I might be a good fit for your company? I am open for something new!',
    de: 'Glaubst Du, wir würden gut zusammen passen? Ich bin offen für etwas Neues!'
};

export var ALL_TAGS = [
    'frontend',
    'backend',
    'devops/sre',
    'tech&#8239;health',
    'consulting',
    'other',
    'non&#8209;tech',
];
export var HIDE_NON_TECH_INITIALLY = true;
export var SCROLL_OFFSET = 750;
export var DATE_FORMAT = 'MM/YY';

import { __assign } from "tslib";
import { writable } from 'svelte/store';
import { HIDE_NON_TECH_INITIALLY } from '../constants';
import data from '../data';
import { filterNonTech } from '../utility';
import { cvJobItemsState, cvEduItemsState, skillsState, } from './data';
export var activeFiltersState = writable([]);
export var skillLevelFilterState = writable(1);
export var hideNonTechState = writable(HIDE_NON_TECH_INITIALLY);
var compareFiltersToItems = function (items, filters) {
    if (!filters.length) {
        return items;
    }
    var newItems = items.filter(function (_a) {
        var tags = _a.tags;
        var inter = tags.filter(function (tag) {
            return filters.includes(tag);
        });
        return Boolean(inter.length);
    });
    return newItems;
};
export var filterData = function (filters, hideNonTechState) {
    cvJobItemsState.set(__assign(__assign({}, data.cvJobItems), { items: filterNonTech(hideNonTechState, compareFiltersToItems(data.cvJobItems.items, filters)) }));
    cvEduItemsState.set(__assign(__assign({}, data.cvEduItems), { items: filterNonTech(hideNonTechState, compareFiltersToItems(data.cvEduItems.items, filters)) }));
    skillsState.set(__assign(__assign({}, data.skills), { items: data.skills.items.map(function (skill) { return (__assign(__assign({}, skill), { items: filterNonTech(hideNonTechState, compareFiltersToItems(skill.items, filters)) })); }) }));
};
export var filterSkills = function (n) {
    skillLevelFilterState.set(n);
    skillsState.set(__assign(__assign({}, data.skills), { items: data.skills.items.map(function (skill) { return (__assign(__assign({}, skill), { items: skill.items.filter(function (_a) {
                var level = _a.level;
                return level >= n || !level;
            }) })); }) }));
};

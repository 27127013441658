import { isSingleLanguageContentTypeGuard, } from './types';
export var filterNonTech = function (enforce, items) {
    if (!enforce) {
        return items;
    }
    return items.filter(function (_a) {
        var tags = _a.tags;
        return !tags.includes('non&#8209;tech');
    });
};
// handle clicks outside of DOM node
export var clickOutside = function (node) {
    var handleClick = function (event) {
        if (node &&
            !node.contains(event.target) &&
            !event.defaultPrevented) {
            node.dispatchEvent(new CustomEvent('outsideClick', node));
        }
    };
    document.addEventListener('click', handleClick, true);
    return {
        destroy: function () {
            document.removeEventListener('click', handleClick, true);
        }
    };
};
export var getIntlContent = function (intlContent, contentLangState) {
    if (isSingleLanguageContentTypeGuard(intlContent)) {
        return intlContent.intl;
    }
    return intlContent[contentLangState];
};

<h2
  class="mx-auto my-8 max-w-fit text-xl font-bold print:hidden lg:mb-24 lg:mt-12 xl:max-w-4xl"
>
  <slot />
</h2>

<!-- Print version -->
<h2 class="ml-4 hidden text-2xl font-bold print:block">
  <slot />
</h2>

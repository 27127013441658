export var LevelEnum;
(function (LevelEnum) {
    LevelEnum[LevelEnum["been confronted"] = 0] = "been confronted";
    LevelEnum[LevelEnum["used before"] = 1] = "used before";
    LevelEnum[LevelEnum["familiar"] = 2] = "familiar";
    LevelEnum[LevelEnum["well-versed"] = 3] = "well-versed";
    LevelEnum[LevelEnum["expert"] = 4] = "expert";
})(LevelEnum || (LevelEnum = {}));
export var isSingleLanguageContentTypeGuard = function (content) {
    return content.intl !== undefined;
};

import { writable } from 'svelte/store';
export var showSidebarState = writable(false);
export var contentLangState = writable('en');
export var showModalState = writable();
export var openModalWithValues = function (modal) {
    showModalState.update(function (state) {
        if (state === undefined) {
            return modal;
        }
        return state;
    });
};
export var closeModal = function () {
    showModalState.set(undefined);
};
